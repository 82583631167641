import React, { RefObject, forwardRef, useRef } from 'react'
import { useSafeState, useMount, useUpdate } from 'ahooks';

import { BottomNavBar } from '../home/navBar'

// -----------------------------------------------------------------------
const ProfilePage = forwardRef((props = {}, ref) => {
    return (
        <div className="app">
            <div className='body'>
                <span>我的内容</span>
            </div>
            <BottomNavBar />
        </div>
    )
})





// -----------------------------------------------------------------------
export { ProfilePage }