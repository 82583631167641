
import { api, SERVER, buildServerUrl } from 'model/api/api';

// -------------------------------------------------------------------------------
export let apiRecognABO = async ( data, extra, serverType=SERVER.TYPE.NORMAL) => {
    let url = buildServerUrl("/hh/v1/app/recogn/ABO", serverType)
    if (url === undefined) {
        return undefined
    }
    return await api(url, data, extra)
}

export let apiRecognCortisol = async ( data, extra, serverType=SERVER.TYPE.NORMAL) => {
    let url = buildServerUrl("/hh/v1/app/recogn/Cortisol", serverType)
    if (url === undefined) {
        return undefined
    }
    return await api(url, data, extra)
}
