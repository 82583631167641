import React from 'react';
import ReactDOM from 'react-dom';

import {BrowserRouter, Route} from 'react-router-dom';
import {Switch} from 'react-router';
import { ConfigProvider } from 'antd-mobile'
import './css/theme.css';
import './css/ho.css';
// import App from './App';
import * as serviceWorker from './serviceWorker';

import AppRoute from './comm/route';

// import enUS from 'antd-mobile/es/locales/en-US'
import zhCN from 'antd-mobile/es/locales/zh-CN'



// ---------------------------------------------------------------------
const routes = (
    <ConfigProvider locale={zhCN}>
        <BrowserRouter>
            <Switch>
                <Route path='/' component={AppRoute}/>
            </Switch>
        </BrowserRouter>
    </ConfigProvider>
);


ReactDOM.render(routes, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
