import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';


// ************************************************************
// 各模块路由表配置
// ************************************************************

import { HomePage } from "pages/home/home"
import { InformationPage } from "pages/information/information"
import { ShopPage } from 'pages/shop/shop'
import { RecognitionPage } from 'pages/recognition/recognition'
import { ProfilePage } from 'pages/profile/profile'


// ---------------------------------------------------------------------------
function AppRoute(props) {
    let {match} = props

    return (
        <Switch>
            <Route path={`${match?.path}`} exact component={HomePage} />

            <Route path={`${match?.path}home`} exact component={HomePage} />
            <Route path={`${match?.path}shop`} exact component={ShopPage} />
            <Route path={`${match?.path}recognition`} exact component={RecognitionPage} />
            <Route path={`${match?.path}information`} exact component={InformationPage} />
            <Route path={`${match?.path}profile`} exact component={ProfilePage} />
            

        </Switch>
    );
}


export default AppRoute

