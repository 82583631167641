import React, { RefObject, forwardRef, useRef, useImperativeHandle, useEffect } from 'react'
import { useSafeState, useMount, useUpdate, useMemoizedFn } from 'ahooks';
import { Grid, Button, Space, Modal, Radio, Tabs, Image  } from 'antd-mobile'
import { VideoOverlay } from 'component/comm'
import Webcam from "react-webcam";

import { BottomNavBar } from 'pages/home/navBar'
import { cropDataURLImage, recognABORequest, recognCortisolRequest } from 'model/recognition/recognition'
import { TouchIcon, DountIcon, SwitchCameraIcon } from 'component/icons'
import { COLOR } from 'component/comm'
import { dataABO, dataCor } from 'model/recognition/testData'

// -----------------------------------------------------------------------
const RecognitionPage = forwardRef((props = {}, ref) => {
    const [bottomHeight, setBottomHeight] = useSafeState()
    useMount(() => {
        const bottomBar = document.getElementById("id-bottom-bar")
        if (bottomBar) {
            setBottomHeight(bottomBar.clientHeight)
        }
    })

    return (
        <div className="app">
            <div className='body'>
                <RecognContent bottomHeight={bottomHeight}/>
            </div>
            <BottomNavBar />
        </div>
    )
})


const RecognContent = forwardRef((props = {}, ref) => {
    let { bottomHeight } = props

    const [cameraList, setCameraList] = useSafeState([])
    const [videoConfig, setVideoConfig] = useSafeState({facingMode: "environment"})
    const [selectCamera, setSelectCamera] = useSafeState(undefined)
    const [videoWidth, setVideoWidth] = useSafeState()
    const [videoHeight, setVideoHeight] = useSafeState()
    const [activeTab, setActiveTab] = useSafeState("ABO")

    const webcamRef = useRef()
    const videoStreamRef = useRef(undefined)
    const selectCameraRef = useRef(undefined)
    const webWidthRf = useRef()
    const webHeightRf = useRef()
    const resultModalRf = useRef()
    const activeTabRf = useRef("ABO")

    const calcCropPos = (width, height) => {
        let result = {
            x: Math.round(width * 0.15), 
            y: Math.round(height * 0.25), 
            width: Math.round(width * 0.7), 
            height: Math.round(height * 0.25), 
        }
        return result
    }

    const captureOnclickHd = () => {
        const imageData = webcamRef.current.getScreenshot({width: videoWidth, height: videoHeight})
        const cropConfig = calcCropPos(videoWidth, videoHeight)

        setTimeout(async () => {
            if (resultModalRf.current) {
                const displayData = await cropDataURLImage(imageData, cropConfig.x, cropConfig.y, cropConfig.width, cropConfig.height)
                // let displayData = dataCor
                let rsp = undefined
                if (activeTabRf.current === "ABO") {
                    rsp = await recognABORequest(displayData)
                }else{
                    rsp = await recognCortisolRequest(displayData)
                }

                let displayInfo = undefined
                if (rsp?.HasResult) {
                    // 有结果的情况
                    displayInfo = {}
                    displayInfo.BloodGroup = rsp?.BloodGroup
                    displayInfo.ResultUrl = rsp?.ResultUrl
                    displayInfo.RecognResults = rsp?.RecognResults
                    resultModalRf.current.openModal(displayInfo.ResultUrl, activeTabRf.current, displayInfo)
                }else{
                    resultModalRf.current.openModal(displayData, activeTabRf.current, displayInfo)
                }
            }        
        })

    }

    useMount(() => {
        navigator.mediaDevices.enumerateDevices().then((deviceList) => {
            let cameras = deviceList.filter(({ kind }) => kind === "videoinput")
            if (cameras?.length > 0) {
                selectCameraRef.current = cameras[0]
                setSelectCamera(cameras[0])
                console.log("@@@@@@@@@@-->1: cameras = %o", cameras)
                setCameraList(cameras)
            }
        })

        // 获取浏览器的宽和高
        webWidthRf.current = window.innerWidth
        webHeightRf.current = window.innerHeight
    })

    const tabOnchangeHd = (key) => {
        activeTabRf.current = key
        setActiveTab(key)
    }

    const calcVideoSize = (streamWidth, streamHeight) => {
        let result = {width: undefined, height: undefined}
        if (streamWidth === 0 || streamHeight === 0) {
            return
        }

        if (webWidthRf.current && webHeightRf.current) {
            result.width = webWidthRf.current
            result.height = (streamHeight / streamWidth) * webWidthRf.current
            result.height = Math.round(result.height)
        }
        return result
    }

    const videoStreamOkHd = (stream) => {
        videoStreamRef.current = stream
        const videoTrack = stream.getVideoTracks()[0]
        const settings = videoTrack.getSettings()
        // 计算视频窗口的宽和高
        const videoSize = calcVideoSize(settings?.width, settings?.height)
        setVideoWidth(videoSize.width)
        setVideoHeight(videoSize.height)
    }

    const getSelectCamera = (srcList, currentCamera) => {
        const srcLen = srcList.length
        let camera = undefined
        if (srcLen > 1) {
            let currentIndex = 0
            for (let i = 0; i < srcLen; i++) {
                let item = srcList[i]
                if (item?.deviceId === currentCamera?.deviceId) {
                    currentIndex = i
                    break
                }
            }
            let selectIndex = currentIndex + 1
            if (selectIndex >= srcLen) {
                selectIndex = 0
            }
            camera = srcList[selectIndex]
        }

        return camera
    }

    const switchOnclickHd = () => {
        const camera = getSelectCamera(cameraList, selectCamera)
        if (camera) {
            setVideoConfig({deviceId: camera?.deviceId})
            setSelectCamera(camera)
        }
    }

    const getOpHeight = (videoHeight, bottomHeight) => {
        let result = 50
        if (webHeightRf.current) {
            let tmpHeight = 50
            if (bottomHeight) {
                tmpHeight = bottomHeight
            }
            result = webHeightRf.current - videoHeight - tmpHeight - 64
        }

        if (!result) {
            result = 0
        }

        return result
    }

    const getButtonGroup = () => {
        return (
            <div className="camera_ops" style={{height: getOpHeight(videoHeight, bottomHeight)}}>
                <Space style={{ '--gap': '5em' }}>
                    <TouchIcon style={{fontSize: "3em"}} onClick={captureOnclickHd} />
                    <SwitchCameraIcon style={{fontSize: "3em"}} onClick={switchOnclickHd} />
                </Space>
            </div>
        )
    }

    const getOpButtons = () => {
        return (
            <div style={{backgroundColor: "#000000", color: COLOR.GRAY_6}}>
                <Tabs activeLineMode="fixed" 
                    style={{'--title-font-size': "14px", 
                    '--adm-color-border': "#000000", 
                    '--active-title-color': "#ffffff", 
                    '--active-line-color': "#ffffff", 
                }}
                activeKey={activeTab}
                onChange={tabOnchangeHd}
                >
                    <Tabs.Tab title="ABO（血型）" key="ABO">
                        {getButtonGroup()}
                    </Tabs.Tab>

                    <Tabs.Tab title="Cortisol（压力）" key="Cortisol">
                        {getButtonGroup()}
                    </Tabs.Tab>
                </Tabs>
            </div>
        )
    }

    return (
        <div className="col">
            <VideoOverlay hintText="请将试纸显示窗放置在扫描框内">
                <Webcam ref={webcamRef}
                    audio={false}
                    width={videoWidth}
                    high={videoHeight}
                    screenshotFormat="image/jpeg"
                    onUserMedia={videoStreamOkHd}
                    onUserMediaError={undefined}
                    videoConstraints={videoConfig}
                    id="video_capture_id"
                    />
            </VideoOverlay>
            {getOpButtons()}
            <ResultModal ref={resultModalRf}/>
        </div>
    )
})


const ResultModal = forwardRef((props = {}, ref) => {
    const [show, setShow] = useSafeState(false)
    const [dataUrl, setDataUrl] = useSafeState()
    const [displayType, setDisplayType] = useSafeState("ABO")
    const [displayInfo, setDisplayInfo] = useSafeState()

    const openModal = useMemoizedFn((dataURL, displayType, displayInfo) => {
        setDataUrl(dataURL)
        setDisplayInfo(displayInfo)
        setDisplayType(displayType)
        setShow(true)
    })

    const closeModal = () => {
        setDataUrl(undefined)
        setDisplayInfo(undefined)
        setShow(false)
    }

    useImperativeHandle(ref, () => ({
        openModal, 
    }), [openModal])

    const getTitle = (type) => {
        if (type === "ABO") {
            return "血型检测结果"
        }

        return "压力检测结果"
    }

    const getDesc = () => {
        if (displayInfo === undefined) {
            return (
                <div style={{marginTop: "16px"}}>
                    <span style={{fontSize: "1em"}}>未识别到结果！请确认拍照时试纸是否已放置在扫描框内</span>
                </div>
            )
        }

        let diplayLines = displayInfo?.RecognResults.map((item, index) => 
                (<div className='row_left' key={index}><span key={index}>线{index + 1}：位置{item?.ColPos}， 亮度{item?.BV}</span></div>)
            )

        if (displayType === "ABO") {
            return (
                <div style={{marginTop: "16px"}}>
                    <div className='row_left'>
                        <span style={{fontSize: "1.5em", color: COLOR.RED_6}}>{displayInfo?.BloodGroup} 型</span>
                    </div>
                    {diplayLines}
                </div>
            )
        }

        if (displayType === "Cortisol") {
            return (
                <div style={{marginTop: "16px"}}>
                    {diplayLines}
                </div>
            )
        }

        return undefined
    }

    const getDisplayContent = () => {
        return (
            <div>
                <div className='row_left' style={{marginBottom: "8px"}}>
                    <span >{getTitle(displayType)}</span>
                </div>
                <Image src={dataUrl}/>

                {getDesc()}

            </div>
        )
    }

    return (
        <Modal visible={show} 
        closeOnAction
        onClose={closeModal}
        content={getDisplayContent()}
        actions={[
            {
            key: 'confirm',
            text: '我知道了',
            },]}
        >
            
        </Modal>
    )
})




// -----------------------------------------------------------------------
export { RecognitionPage }