/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */

import JSEncrypt from "jsencrypt";
import { customAlphabet } from 'nanoid/non-secure';

// --------------------------------------------------------------------------------------------------------
const nanoString = customAlphabet('ABCDEFGHJKMNPQRSTWXYZ2345678', 10)
const nanoNumber = customAlphabet('0123456789', 10)

// 使用命令生成公钥、私钥
// openssl genrsa -out rsa_private_key.pem 1024
// openssl rsa -in rsa_private_key.pem -pubout -out rsa_public_key.pem
// 公钥
const PUBLIC_KEY = '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC7qY7rpCBeN18I0GwuHQZFGVxOB+nw6AwokOhU5+t5EHwWEfN6hWmvA7NRNFH85ncSS3+Z25gk878It3cD1dI0Wgh1iR1nJt0n38JQAGArsGR3a4VkhRVEuz5ygjq6DzWxJcGpqiQsmCr41oFiCbwZjhn+NyyjahuJzzUdlGmukwIDAQAB-----END PUBLIC KEY-----';

// --------------------------------------------------------------------------------------------------------
export function encrypt(str){
    //使用公钥加密
    let encrypt = new JSEncrypt();
    encrypt.setPublicKey(PUBLIC_KEY);
    return encrypt.encrypt(str);
}

export function decrypt(str){
    //使用公钥解密
    let encrypt = new JSEncrypt();
    encrypt.setPublicKey(PUBLIC_KEY);
    return encrypt.decrypt(str);
}

export function randomString(len) {
    return nanoString(len);
}

export function randomNumber(len) {
    return nanoNumber(len);
}

export function randomFind(array, compFun) {
    let newArray = []
    array.forEach(element => {
        if (compFun(element)) {
            newArray.push(element)
        }
    });
    return newArray[Math.floor(Math.random() * newArray.length)];
}

export const hh_log = (content) => {
    console.log(content)
}