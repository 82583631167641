import React, { RefObject, forwardRef, useRef } from 'react'
import { useSafeState, useMount, useUpdate } from 'ahooks';

import { BottomNavBar } from './navBar'

// -----------------------------------------------------------------------
const HomePage = forwardRef((props = {}, ref) => {
    return (
        <div className="app">
            <div className='body'>
                <span>首页内容</span>
            </div>
            <BottomNavBar />
        </div>
    )
})





// -----------------------------------------------------------------------
export { HomePage }
