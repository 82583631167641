/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */

import { extend } from "umi-request";
import { randomString } from 'model/util/logic'

// -----------------------------------------------------------------------------------------
const SERVER = {
    TYPE: {
        TEST: 'test', 
        NORMAL: 'normal',
    }, 
    BASEURL: {
        TEST: 'http://health.angliancd.com:8052', 
        NORMAL: 'http://health.angliancd.com:8052', 
    }
}


// -----------------------------------------------------------------------------------------
const request = extend({
    headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json',
    },
    timeout: 5000, 
} 
); 

// 默认为post请求
let api = async (url, data, token="SubdH0c3JskxyVte5i9Zqimsl7b6EPUb") => {
    let uuid = randomString(8)
    let otps = {
        data: data,
        uuid: uuid,
    }
    if (token) {
        otps.headers = {Token: token}
    }

    let rsp = await request
        .post(url, otps)
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
    
    return rsp || {};
}

const buildServerUrl = (path, serverType=SERVER.TYPE.NORMAL) => {
    let result = undefined
    if (serverType === SERVER.TYPE.NORMAL) {
        result = SERVER.BASEURL.NORMAL + path
    }else if (serverType === SERVER.TYPE.TEST) {
        result = SERVER.BASEURL.TEST + path
    }
    return result
}


export { api, SERVER, buildServerUrl }
