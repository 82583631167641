import React, { RefObject, forwardRef, useRef } from 'react'
import { useSafeState, useMount, useUpdate } from 'ahooks';
import { useHistory, useLocation } from 'react-router-dom'

import { TabBar } from 'antd-mobile'
import { AppOutline, ScanningOutline, UserOutline, GiftOutline, ContentOutline } from 'antd-mobile-icons'


// -----------------------------------------------------------------------
const BottomNavBar = forwardRef((props = {}, ref) => {
    const history = useHistory()
    const location = useLocation()
    const { pathname } = location

    const setRouteActive = (value) => {
        history.push(value)
    }

    const getActiveKey = () => {
        console.log("###########: pathname = %o", pathname)
        if (pathname === "/") {
            return "/home"
        }else{
            return pathname
        }
    }

    const tabs = [
        {
            key: '/home', 
            title: "首页",
            icon: <AppOutline />,
        }, 
        {
            key: '/shop', 
            title: "商城",
            icon: <GiftOutline />,
        }, 
        {
            key: '/recognition', 
            title: "识别",
            icon: <ScanningOutline />,
        }, 
        {
            key: '/information', 
            title: "资讯",
            icon: <ContentOutline />,
        }, 
        {
            key: '/profile', 
            title: "我的",
            icon: <UserOutline />,
        }, 
    ]


    return (
        <div className='bottom' id="id-bottom-bar">
            <TabBar activeKey={getActiveKey()} onChange={value => setRouteActive(value)}>
                {tabs.map(item => (
                    <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
                ))}
            </TabBar>
        </div>
    )
})


// -----------------------------------------------------------------------
export { BottomNavBar }

