import { apiRecognABO, apiRecognCortisol } from 'model/api/recogn'
import { hh_log } from 'model/util/logic'

// -----------------------------------------------------------------------
function cropDataURLImage(dataURL, x, y, width, height) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = function() {
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, -x, -y); // 在画布上绘制图像，使用负值来进行裁剪
            resolve(canvas.toDataURL());
        };
        img.onerror = function(error) {
            reject(error);
        };
        img.src = dataURL;
    });
}

const recognABORequest = async (dataURL) => {
    let req = {ImgData: dataURL}

    let result = {HasResult: false}
    try {
        let rsp = await apiRecognABO(req)
        if (rsp?.Status !== 0) {
            hh_log(`recognABORequest: apiRecognABO request failed! Status = ${rsp?.Status}, StatusMsg = ${rsp?.StatusMsg}`)
            return result
        }
        result.HasResult = true
        result.BloodGroup = rsp?.BloodGroup
        result.ResultUrl = rsp?.ResultUrl
        result.RecognResults = rsp?.RecognResults
        return result
    }catch(error){
        hh_log(`recognABORequest: apiRecognABO request failed! error = ${error}`)
    }

    return result
}

const recognCortisolRequest = async (dataURL) => {
    let req = {ImgData: dataURL}

    let result = {HasResult: false}
    try {
        let rsp = await apiRecognCortisol(req)
        if (rsp?.Status !== 0) {
            hh_log(`recognCortisolRequest: apiRecognCortisol request failed! Status = ${rsp?.Status}, StatusMsg = ${rsp?.StatusMsg}`)
            return result
        }
        result.HasResult = true
        result.ResultUrl = rsp?.ResultUrl
        result.RecognResults = rsp?.RecognResults
        return result
    }catch(error){
        hh_log(`recognCortisolRequest: apiRecognCortisol request failed! error = ${error}`)
    }

    return result
}


// -----------------------------------------------------------------------
export { cropDataURLImage, recognABORequest, recognCortisolRequest }

